import {
  GetStaticPathsContext,
  GetStaticPathsResult,
  GetStaticPropsContext,
  InferGetStaticPropsType,
} from 'next'
import {DrupalNode} from 'next-drupal'
import safeJsonStringify from 'safe-json-stringify'
import {drupal, getDrupalResourceWithAdditionalPages} from '../lib/drupal'
import {getParams} from '../lib/get-params'
import {Layout} from '../components/layout/index'
import ParagraphMapper from '../components/ParagraphMapper'
import NodeMapper from '../components/NodeMapper'
import {
  getTranslationPathForVacancy,
  getTranslations,
} from '../lib/get-dictionary-items'
import {Node, ResourceOverviewNode} from '../types/content-types'
import {getResourcesAlgolia} from '../lib/get-resources-algolia'
import {
  AdditionalContent,
  AdditionalContentTaxonomies,
  ContextParams,
  MatchboxVacancyEnriched,
} from '../types/api-types'
import Breadcrumb from '../components/shared/Breadcrumb'
import {getPathsFromDrupal, getSeoPagePaths} from '../lib/get-paths-from-drupal'
import striptags from 'striptags'
import {SharedDataStrategy} from '../lib/cacheStrategies/sharedDataStrategy'
import {
  APPLICATIONSTEPS_CACHEFILENAME,
  localizedCacheFileExists,
  MENUS_CACHEFILENAME,
  OFFICES_CACHEFILENAME,
  saveToCacheFile,
  saveToLocalizedCacheFile,
  TAXONOMIES_CACHEFILENAME,
  TEAL_TAXONOMIES_CACHEFILENAME,
} from '../lib/cacheFileHelper'
import {SharedDataFileSystem} from '../lib/cacheStrategies/sharedDataStrategy.filesystem'
import {SharedDataRealtime} from '../lib/cacheStrategies/sharedDataStrategy.realtime'
import {SharedDataRemoteCache} from '../lib/cacheStrategies/sharedDataStrategy.remoteCache'
import {
  getDictionaryFromRedis,
  getDictionaryItemFromRedis,
  getDrupalCollection,
  getTokenHandler,
} from '../lib/redis'
import {getMenuFromDrupal} from '../lib/get-menus'
import {useRouter} from 'next/router'
import {jobsPerPage2Panes, localeCountryMap} from '../constants'
import {
  getEmployerStats,
  getJobDetails,
  getJobs,
  getRelatedJobDetails,
  getTotalAmountVacancies,
} from '../lib/matchbox'
import {getInnerTextFromRichtext} from '../utils/url-from-richtext'
import {
  JobOverviewParagraph,
  JobOverview2PanesParagraph,
} from '../types/paragraphs'
import {getTealTaxonomies} from '../lib/get-teal-data'
import {PageInfoProvider} from '../utils/hooks/usePageInfo'

const RESOURCE_TYPES = [
  'node--content_page',
  'node--job_overview',
  'node--blog_article',
  'node--homepage_jobs',
  'node--resource_overview',
  'node--pillar_page',
  'node--employer_detail_page',
  'node--office_overview',
  'node--office',
]

const isNumeric = (num: any) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number)

export type SlugProps = InferGetStaticPropsType<typeof getStaticProps>

export default function ResourcePage({
  resource,
  additionalContent,
  menus,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter()
  const country =
    localeCountryMap[
      (router.locale?.toLowerCase() as
        | keyof typeof localeCountryMap
        | undefined) ?? 'nl-be'
    ]

  const defaultQuery = (
    resource?.field_paragraphs?.find(
      paragraph => paragraph.type === 'paragraph--job_overview_two_panes',
    ) as JobOverview2PanesParagraph | undefined
  )?.field_default_query

  if (!resource) {
    return null
  }
  return (
    <Layout menus={menus ?? null} resource={resource}>
      {resource.type === 'node--homepage_jobs' ||
      !resource.breadcrumbs ? null : (
        <Breadcrumb
          breadCrumb={resource.breadcrumbs}
          pageType={resource.type}
        />
      )}
      <NodeMapper
        node={resource}
        country={country}
        additionalContent={additionalContent}
      >
        <PageInfoProvider
          pageType={resource.type}
          defaultSearchQuery={defaultQuery}
          locationTokenQuery={additionalContent.initialLocationQuery?.value}
        >
          {resource.field_paragraphs?.map(paragraph => (
            <ParagraphMapper
              key={paragraph.id}
              country={country}
              paragraph={paragraph}
              additionalContent={additionalContent}
            />
          ))}
          {resource.type === 'node--office' && resource.field_job_overview ? (
            <ParagraphMapper
              country={country}
              paragraph={resource.field_job_overview}
              additionalContent={additionalContent}
            />
          ) : null}
        </PageInfoProvider>
      </NodeMapper>
    </Layout>
  )
}

export async function getStaticPaths(
  context: GetStaticPathsContext,
): Promise<GetStaticPathsResult> {
  const localesWithoutDefault =
    context.locales?.filter(el => el !== 'default') ?? []
  if (process.env.NODE_ENV === 'production' && process.env.APP_ENV !== 'dev') {
    for (let i = 0; i < localesWithoutDefault.length; i += 1) {
      const locale = localesWithoutDefault[i]
      const [menus, countryTaxonomies, offices, applicationSteps] =
        await Promise.all([
          getMenuFromDrupal({...context, locale}),
          getDrupalCollection(
            'taxonomy_term--country',
            getParams('taxonomy_term--country', locale).getQueryObject(),
          ),
          getDrupalResourceWithAdditionalPages(
            'group_content--site-group_node-office',
            locale,
          ),
          getDrupalResourceWithAdditionalPages(
            'node--application_process_step',
            locale,
          ),
        ])
      saveToLocalizedCacheFile(MENUS_CACHEFILENAME, menus, locale)
      saveToLocalizedCacheFile(
        `${TAXONOMIES_CACHEFILENAME}:country`,
        countryTaxonomies,
        locale,
      )
      saveToLocalizedCacheFile(OFFICES_CACHEFILENAME, offices, locale)
      saveToLocalizedCacheFile(
        APPLICATIONSTEPS_CACHEFILENAME,
        applicationSteps,
        locale,
      )
    }
    const tealTaxonomies = await getTealTaxonomies()
    saveToCacheFile(TEAL_TAXONOMIES_CACHEFILENAME, tealTaxonomies)
  }
  if (process.env.LIGHTHOUSE_ACTIVE || process.env.APP_ENV === 'dev') {
    return {paths: [], fallback: 'blocking'}
  }
  const [pathsFromDrupal] = await Promise.all([
    getPathsFromDrupal(),
    getTranslations(),
  ])
  const paths = (
    pathsFromDrupal?.filter(el => {
      if (!el.path) {
        return false
      }
      const splittedString = el.path?.split('/')
      if (!localesWithoutDefault.some(el => el === splittedString[1])) {
        return false
      }
      return true
    }) ?? []
  )
    .map(el => el.path)
    .map(pathFromDrupal => {
      const splittedString = pathFromDrupal.split('/')
      return {
        params: {
          slug:
            splittedString.slice(2)[0] === 'home-jobs'
              ? []
              : splittedString.slice(2),
        },
        locale: `${splittedString[1]}`,
      }
    })
  return {paths, fallback: 'blocking'}
}

export async function getStaticProps(context: GetStaticPropsContext) {
  const country =
    localeCountryMap[
      (context.locale?.toLowerCase() as
        | keyof typeof localeCountryMap
        | undefined) ?? 'nl-be'
    ]
  context = {...context, ...{defaultLocale: process.env.DEFAULTLOCALE}}
  const is2panesDesign = process.env.LAYOUT_JOB_OVERVIEW === '2panes'
  let sharedDataStrategy: SharedDataStrategy
  if (process.env.NODE_ENV !== 'production') {
    // npm run dev
    sharedDataStrategy = new SharedDataRealtime()
  } else if (
    await localizedCacheFileExists(
      MENUS_CACHEFILENAME,
      context.locale!.toLowerCase(),
    )
  ) {
    // buildtime OR localhost
    sharedDataStrategy = new SharedDataFileSystem()
  } else if (context.preview) {
    // preview OR runtime
    sharedDataStrategy = new SharedDataRealtime()
  } else {
    sharedDataStrategy = new SharedDataRemoteCache()
  }

  const {slug} = context.params as ContextParams

  const [menus] = await Promise.all([
    sharedDataStrategy.getMenu(context),
    getTranslations(),
  ])

  let jobSlug = await getDictionaryItemFromRedis(
    context.locale!,
    'jobs_job_detail_slug',
  )
  jobSlug = jobSlug ? striptags(jobSlug).replace(/\r\n/g, '').toLowerCase() : ''

  let applicationSlug = await getDictionaryItemFromRedis(
    context.locale!,
    'jobs_application_form_slug',
  )
  applicationSlug = applicationSlug
    ? striptags(applicationSlug).replace(/\r\n/g, '').toLowerCase()
    : ''

  let overViewBreadcrumbLinkSlug = await getDictionaryItemFromRedis(
    context.locale!,
    'jobs_job_overview_breadcrumb_link',
  )
  const overViewBreadcrumbPath = overViewBreadcrumbLinkSlug
    ? striptags(jobSlug).replace(/\r\n/g, '').toLowerCase()
    : ''

  const jobDetailsPageBreadCrumbLabel = getInnerTextFromRichtext(
    overViewBreadcrumbLinkSlug,
  )

  let additionalContent: AdditionalContent = {}
  let resource = null
  let isSEOPage = false
  let seoSlug = ''
  let seoPageInfo = null

  if (
    slug &&
    slug[0] === jobSlug &&
    slug[1] &&
    !isNumeric(slug[1].split('-')[0]) &&
    !slug[1].split('-')[0].startsWith('page=') // don't consider the pagination slug
  ) {
    // is an seo page
    isSEOPage = true
    seoSlug = slug[1]
    seoPageInfo = await sharedDataStrategy.getSeoPageInfo(
      context.locale ?? 'nl-be',
      (context.params?.slug?.slice(0, 2) as string[] | undefined) ?? [],
    )
    if (seoPageInfo && seoPageInfo.type && seoPageInfo.id) {
      resource = await drupal.getResource<DrupalNode & Node>(
        seoPageInfo.type,
        seoPageInfo.id,
        {
          params: getParams(seoPageInfo.type, context.locale!).getQueryObject(),
          locale: context.locale!,
          defaultLocale: context.defaultLocale!,
          withAuth: true,
        },
      )
      if (seoPageInfo.placeholders?.location) {
        additionalContent.initialLocationQuery =
          seoPageInfo.placeholders?.location
      }
      if (resource) {
        resource = {
          ...resource,
          breadcrumbs: [
            {
              label:
                resource.breadcrumbs?.[0].label.replace(
                  '$location',
                  seoPageInfo.placeholders?.location?.value ?? '',
                ) ?? '',
              path: `${seoPageInfo.path}`,
            },
            {
              label: jobDetailsPageBreadCrumbLabel,
              path: `/${context.locale}/${overViewBreadcrumbPath}`,
            },
          ],
          metatag_normalized: [
            {
              attributes: {
                name: 'title',
                content:
                  resource.metatag_normalized
                    ?.find(el => el.attributes.name === 'title')
                    ?.attributes.content?.replace(
                      '$location',
                      seoPageInfo.placeholders?.location?.value ?? '',
                    ) ?? null,
              },
              tag: null,
            },
            {
              attributes: {
                rel: 'canonical',
                href: `/${context.locale}/${slug.join('/')}`,
              },
              tag: null,
            },
            {
              attributes: {
                property: 'og:title',
                content:
                  resource.metatag_normalized
                    ?.find(
                      metatag => metatag.attributes?.property === 'og:title',
                    )
                    ?.attributes.content?.replace(
                      '$location',
                      seoPageInfo.placeholders?.location?.value ?? '',
                    ) ?? null,
              },
              tag: null,
            },
            {
              attributes: {
                property: 'og:description',
                content:
                  resource.metatag_normalized
                    ?.find(
                      metatag =>
                        metatag.attributes?.property === 'og:description',
                    )
                    ?.attributes.content?.replace(
                      '$location',
                      seoPageInfo.placeholders?.location?.value ?? '',
                    ) ?? null,
              },
              tag: null,
            },
            {
              attributes: {
                name: 'description',
                content:
                  resource.metatag_normalized
                    ?.find(
                      metatag => metatag.attributes?.property === 'description',
                    )
                    ?.attributes.content?.replace(
                      '$location',
                      seoPageInfo.placeholders?.location?.value ?? '',
                    ) ?? null,
              },
              tag: null,
            },
          ],
        }
      }
    } else {
      return {
        notFound: true,
        revalidate: process.env.APP_ENV === 'prd' ? 900 : 60,
      }
    }
  } else if (
    (!is2panesDesign &&
      slug &&
      (slug[0] === jobSlug || slug[0] === applicationSlug) &&
      slug[1]) ||
    (is2panesDesign && slug && slug[0] === applicationSlug && slug[1])
  ) {
    // when 2 pane design not actief , job detail and application form pages are seperate pages
    const vacancyId = slug[1].split('-')[0]

    const [matchBoxJobDetailsResp, matchBoxRelatedJobDetailsResp] =
      await Promise.all([
        getJobDetails(vacancyId, context.locale!),
        getRelatedJobDetails(vacancyId, context.locale!, 0, 4),
      ])

    if (!matchBoxJobDetailsResp) {
      return {
        notFound: true,
        revalidate: process.env.APP_ENV === 'prd' ? 60 : 60,
      }
    }

    if (
      matchBoxRelatedJobDetailsResp &&
      matchBoxRelatedJobDetailsResp.length > 0
    ) {
      additionalContent['relatedJobs'] = matchBoxRelatedJobDetailsResp
    }

    if (matchBoxJobDetailsResp) {
      const offices = await sharedDataStrategy.getOffices(context)

      const office = offices?.find(
        office =>
          office.entity_id.field_id ==
          matchBoxJobDetailsResp.document.extraProperties.officeId,
      )

      // find the office data of the job
      if (office && additionalContent) {
        additionalContent['offices'] = [{entity_id: office?.entity_id}]
      }

      if (matchBoxJobDetailsResp.document.extraProperties.companyId) {
        const companyResponse = await drupal.getResourceCollection(
          'group_content--site-group_node-employer_detail_page',
          {
            params: {
              include: 'entity_id',
              'filter[entity_id.langcode]': context.locale?.toLowerCase(),
              'filter[gid.field_machine_name]': country,
              'filter[entity_id.field_company_id]':
                matchBoxJobDetailsResp.document.extraProperties.companyId,
            },
            locale: context.locale!,
            defaultLocale: process.env.DEFAULTLOCALE!,
            withAuth: true,
          },
        )
        additionalContent['employerData'] =
          companyResponse[0]?.entity_id ?? null
      }

      const sanitizedlistOfLocales = context.locales!.slice(1)
      const hreflang = sanitizedlistOfLocales.flatMap(async locale => {
        return {
          attributes: {
            hreflang: locale,
            rel: 'alternate',
            href: `/${locale}/${striptags(
              jobSlug ? jobSlug : '',
            ).toLowerCase()}/${slug[1]}`,
          },
          tag: null,
        }
      })

      resource = {
        ...matchBoxJobDetailsResp,
        title: matchBoxJobDetailsResp.document.extraProperties.title,
        id: 'vacancy',
        breadcrumbs:
          // breadcrumb for application page is handled in page itself
          slug[0] === applicationSlug
            ? null
            : [
                {
                  label: matchBoxJobDetailsResp.document.extraProperties.title,
                  path: `/${context?.locale}/${slug[0]}${matchBoxJobDetailsResp.document.extraProperties.detailPage}`,
                },
                ...(slug[0] === jobSlug
                  ? [
                      {
                        label: jobDetailsPageBreadCrumbLabel,
                        path: `/${context.locale}/${overViewBreadcrumbPath}`,
                      },
                    ]
                  : []),
              ],
        path_translations: await Promise.all(
          context
            .locales!.filter(loc => loc !== 'default')
            .map(locale =>
              getTranslationPathForVacancy(
                locale,
                slug[0] === jobSlug
                  ? 'jobs_job_detail_slug'
                  : 'jobs_application_form_slug',
                `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}/${context.locale}/${slug}`,
              ),
            ),
        ),
        field_paragraphs: null,
        type: slug[0] === jobSlug ? 'node--vacancy' : 'node--application-form',
        path: null,
        langcode: context.locale!,
        metatag_normalized: [
          ...(await Promise.all(hreflang)),
          {
            attributes: {
              name: 'title',
              content: `${matchBoxJobDetailsResp.document.extraProperties.title} | SD Worx Jobs`,
            },
            tag: null,
          },
          {
            attributes: {
              rel: 'canonical',
              href: `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}/${
                context.locale
              }/${slug.join('/')}`,
            },
            tag: null,
          },
          {
            attributes: {
              property: 'og:title',
              href: `${matchBoxJobDetailsResp.document.extraProperties.title} | SD Worx Jobs`,
            },
            tag: null,
          },
          {
            attributes: {
              property: 'og:description',
              href: matchBoxJobDetailsResp.document.extraProperties
                .introWithKeywords
                ? matchBoxJobDetailsResp.document.extraProperties
                    .introWithKeywords.text
                : '',
            },
            tag: null,
          },
          {
            attributes: {
              name: 'description',
              content: matchBoxJobDetailsResp.document.extraProperties
                .introWithKeywords
                ? matchBoxJobDetailsResp.document.extraProperties
                    .introWithKeywords.text
                : '',
            },
            tag: null,
          },
        ],
      }
    }
  } else {
    // a Drupal page
    let drupalPath = null

    if (
      is2panesDesign &&
      slug &&
      slug[0] === jobSlug &&
      slug[1] &&
      (isNumeric(slug[1].split('-')[0]) || slug[1].startsWith('page='))
    ) {
      //jobSlug
      drupalPath = await drupal.translatePath(`/${context.locale}/${slug[0]}`, {
        withAuth: true,
      })
    } else {
      drupalPath = await drupal.translatePathFromContext(context, {
        withAuth: true,
      })
    }

    if (
      !drupalPath ||
      (drupalPath.jsonapi &&
        !RESOURCE_TYPES.includes(drupalPath.jsonapi.resourceName))
    ) {
      return {
        notFound: true,
        revalidate: process.env.APP_ENV === 'prd' ? 900 : 60,
      }
    } else {
      resource = await drupal.getResourceFromContext<DrupalNode & Node>(
        drupalPath,
        context,
        {
          params: drupalPath.jsonapi
            ? getParams(
                drupalPath.jsonapi.resourceName,
                context.locale!,
              )?.getQueryObject()
            : undefined,
          withAuth: true,
        },
      )
      if (!resource && drupalPath && drupalPath.jsonapi) {
        throw new Error(
          `Failed to fetch resource: ${drupalPath.jsonapi.individual}`,
        )
      }
    }
  }

  if (!resource) {
    return {
      notFound: true,
      revalidate: process.env.APP_ENV === 'prd' ? 900 : 60,
    }
  }
  if (
    resource.field_paragraphs?.find(
      el => el.type === 'paragraph--job_search_link',
    )
  ) {
    additionalContent['totalAmountVacancies'] =
      (await getTotalAmountVacancies(context.locale ?? 'nl-be')) ?? null
    additionalContent['seoPagePaths'] =
      (await getSeoPagePaths(context.locale ?? 'nl-be')) ?? null
  }

  if (resource?.type === 'node--resource_overview') {
    const resourcesAlgolia = await getResourcesAlgolia(
      context.locale ? context.locale : '',
      (resource as unknown as ResourceOverviewNode).field_number_of_items ?? 6,
    )
    if (resourcesAlgolia) {
      additionalContent['blogsFromAlgolia'] = resourcesAlgolia
    }
  }

  if (resource?.type === 'node--pillar_page') {
    const resourcesAlgolia = await getResourcesAlgolia(
      context.locale ? context.locale : '',
      (resource as unknown as ResourceOverviewNode).field_number_of_items ?? 6,
      resource.title,
    )
    if (resourcesAlgolia) {
      additionalContent['blogsFromAlgolia'] = resourcesAlgolia
    }
  }
  if (
    resource?.field_paragraphs?.some(
      paragraph => paragraph.type === 'paragraph--open_application',
    ) ||
    resource?.type === 'node--application-form' ||
    (is2panesDesign &&
      resource?.field_paragraphs?.find(
        paragraph => paragraph.type === 'paragraph--job_overview_two_panes',
      ))
  ) {
    const taxonomies = await sharedDataStrategy.getCountryTaxonomies(context)

    if (taxonomies && taxonomies.length > 0) {
      if (!additionalContent['taxonomies']) {
        additionalContent['taxonomies'] = {} as AdditionalContentTaxonomies
      }
      additionalContent['taxonomies']['countries'] = taxonomies
    }
  }

  // job overview paragraph
  if (
    !is2panesDesign &&
    resource?.field_paragraphs?.find(
      paragraph => paragraph.type === 'paragraph--job_overview',
    )
  ) {
    const paragraph = resource?.field_paragraphs?.find(
      paragraph => paragraph.type === 'paragraph--job_overview',
    ) as JobOverviewParagraph | undefined
    const token = await getTokenHandler()
    // get all job list
    if (token) {
      const initialJobs = await getJobs(
        context.locale ?? 'nl-be',
        0,
        30,
        token,
        paragraph?.field_default_query,
        additionalContent.initialLocationQuery
          ? `cityLabels.${context.locale}:${additionalContent.initialLocationQuery}`
          : undefined,
      )
      if (initialJobs) {
        additionalContent.initialJobs = initialJobs
      }
    }
  }
  // job overview - 2 panes paragraph
  if (
    is2panesDesign &&
    resource?.field_paragraphs?.find(
      paragraph => paragraph.type === 'paragraph--job_overview_two_panes',
    )
  ) {
    const paragraph = resource?.field_paragraphs?.find(
      paragraph => paragraph.type === 'paragraph--job_overview_two_panes',
    ) as JobOverview2PanesParagraph | undefined
    const token = await getTokenHandler()
    // get all job list
    const pageSlug =
      slug.find(path => path.includes('page='))?.split('page=')[1] || 0

    if (token) {
      // for the location filter we will use only keywords and not the city labels
      const initialJobs = await getJobs(
        context.locale ?? 'nl-be',
        pageSlug ? (+pageSlug - 1) * jobsPerPage2Panes : 0,
        jobsPerPage2Panes,
        token,
        paragraph?.field_default_query,
        additionalContent.initialLocationQuery
          ? additionalContent.initialLocationQuery.value?.toLowerCase()
          : undefined,
      )
      if (initialJobs) {
        additionalContent.initialJobs = initialJobs
      }
      // get detail of first Job
      let vacancyId
      if (isSEOPage) {
        vacancyId =
          slug[2] && slug[2] !== `page=${pageSlug}`
            ? slug[2].split('-')[0]
            : initialJobs?.matches[0]?.id
        additionalContent.isSEOPage = true
        additionalContent.seoSlug = seoSlug
      } else {
        vacancyId =
          slug[1] && slug[1] !== `page=${pageSlug}`
            ? slug[1].split('-')[0]
            : initialJobs?.matches[0].id
        additionalContent.isSEOPage = false
      }

      if (vacancyId) {
        const matchBoxJobDetailsResp = await getJobDetails(
          vacancyId,
          context.locale!,
        )
        if (matchBoxJobDetailsResp) {
          additionalContent['jobDetail'] = matchBoxJobDetailsResp
          const offices = await sharedDataStrategy.getOffices(context)

          additionalContent['allOffices'] = offices?.map(o => ({
            entity_id: o?.entity_id,
          }))

          const office = offices?.find(
            office =>
              office.entity_id.field_id ==
              matchBoxJobDetailsResp.document.extraProperties.officeId,
          )

          const foundJobDetailInJobList: MatchboxVacancyEnriched | undefined =
            additionalContent.initialJobs?.matches.find(
              el => el.id === additionalContent?.jobDetail?.id,
            )

          if (
            !foundJobDetailInJobList &&
            additionalContent?.initialJobs?.matches
          ) {
            additionalContent.initialJobs.matches = [
              additionalContent.jobDetail,
              ...additionalContent.initialJobs.matches,
            ]
          }

          // find the office data of the job
          if (office && additionalContent) {
            additionalContent['offices'] = [{entity_id: office?.entity_id}]
          }

          if (matchBoxJobDetailsResp.document.extraProperties.companyId) {
            const companyResponse = await drupal.getResourceCollection(
              'group_content--site-group_node-employer_detail_page',
              {
                params: {
                  include: 'entity_id',
                  'filter[entity_id.langcode]': context.locale?.toLowerCase(),
                  'filter[gid.field_machine_name]': country,
                  'filter[entity_id.field_company_id]':
                    matchBoxJobDetailsResp.document.extraProperties.companyId,
                },
                locale: context.locale!,
                defaultLocale: process.env.DEFAULTLOCALE!,
                withAuth: true,
              },
            )
            if (companyResponse[0]?.entity_id) {
              additionalContent['employerData'] =
                companyResponse[0]?.entity_id ?? null
            }
          }
          additionalContent['doesInitialJobExist'] = true
        } else {
          additionalContent['doesInitialJobExist'] = false
        }
      }
    }
  }
  if (
    resource?.field_paragraphs?.some(
      paragraph => paragraph.type === 'paragraph--news_block',
    )
  ) {
    const resourcesAlgolia = await getResourcesAlgolia(
      context.locale ? context.locale : '',
      4,
      null,
      'main_topic',
    )
    if (resourcesAlgolia) {
      additionalContent['newsBlockAlgolia'] = resourcesAlgolia
    }
  }

  if (resource?.type === 'node--office_overview') {
    const offices = await sharedDataStrategy.getOffices(context)
    if (offices) {
      additionalContent['offices'] = offices
    }
  }
  if (
    resource?.field_paragraphs?.some(
      paragraph => paragraph.type === 'paragraph--employer_overview',
    )
  ) {
    additionalContent['employerStats'] = await getEmployerStats(context.locale!)
  }
  // avoid circular reference, see https://github.com/chapter-three/next-drupal/issues/212
  const stringifiedResource = safeJsonStringify(resource)
  const parsedNode = JSON.parse(stringifiedResource) as DrupalNode & Node

  return {
    props: {
      //get key from redis with proper dictionaries language
      messages: await getDictionaryFromRedis(context.locale!),
      ...menus,
      resource: parsedNode,
      environment: process.env.APP_ENV!,
      additionalContent,
    },
    revalidate: process.env.APP_ENV === 'prd' ? 900 : 60,
  }
}
